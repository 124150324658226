<template>
  <v-text-field
    v-model="searchText"
    @keydown.enter="search.execute"
    @blur="isFocused = false"
    @focus="isFocused = true"
    class="animated border-radius"
    :class="{ shortened, clickable: shortened }"
    :flat="!shortened"
    height=32
    clearable
    dense
    hide-details
    maxlength=2000
    solo
  >
    <template slot="prepend-inner">
      <v-icon>{{ $icon('i.Search') }}</v-icon>
    </template>
  </v-text-field>
</template>

<script>
import Search from '../controllers'

export default {
  data () {
    return {
      isFocused: false
    }
  },
  computed: {
    searchText: {
      get () {
        return this.search.searchText
      },
      set (searchText) {
        this.search.setText(searchText)
      }
    },
    shortened () {
      return !this.searchText.length && !this.isFocused
    }
  },
  props: {
    search: Search
  }
}
</script>

<style lang="stylus" scoped>
.animated
  transition max-width 0.75s ease-in-out

  &>>> .v-input__control
    min-height 32px !important

.shortened
  max-width 48px
</style>
